import React from "react";
import { Box } from "@chakra-ui/layout";
import { Heading, Text } from "@chakra-ui/react";
import SidebarNavLink from "../components/Sidebar/SidebarNavLink";
import AnimationZoom from "../components/AnimationZoom";

const noroute = () => {
    return (
        <AnimationZoom>
            <Box pt="40vh" px="24" textAlign="center" fontSize="xl">
                <Heading fontSize="8xl">404</Heading>
                <Text fontSize="4xl" mb={2}>
                    This route does not exist
                </Text>
                <SidebarNavLink href={"./"}>Back to Home</SidebarNavLink>
            </Box>
        </AnimationZoom>
    );
};

export default noroute;
